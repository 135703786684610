@media (max-width: 541px) {
  .form-control-otp {
    width: 30px;
    min-height: 30px;
    flex-grow: 1;
    & input {
      width: 100%!important;
      height: 100%!important;
    }
  }
}